/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import { FieldType } from 'Component/Field/Field.config';
import { FieldContainerProps } from 'Component/Field/Field.type';
import If from 'Component/If';
import Image from 'Component/Image';
import { NewsletterSubscriptionComponentProps } from 'Component/NewsletterSubscription/NewsletterSubscription.type';
import {
    NewsletterSubscriptionComponent as SourceNewsletterSubscriptionComponent,
} from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.component';
import { ReactElement } from 'Type/Common.type';
import { Device } from 'Type/Device.type';

import background from './assets/newsletter_box.png';
import background_mobile from './assets/newsletter_box-mobile.png';
import newsletterSubscriptionForm from './NewsletterForm.form';

import './NewsletterSubscription.override.style';

export interface ExtendedNewsletterSubscriptionComponentProps extends NewsletterSubscriptionComponentProps {
    device: Device;
}

/** @namespace Pwa/Component/NewsletterSubscription/Component */
export class NewsletterSubscriptionComponent extends SourceNewsletterSubscriptionComponent {
    props!: ExtendedNewsletterSubscriptionComponentProps;

    fieldMap(): Partial<FieldContainerProps>[] {
        return newsletterSubscriptionForm();
    }

    renderActions(): ReactElement {
        return (
            <button
              type={ FieldType.SUBMIT }
              block="Button submit"
              mods={ { isHollow: true } }
              aria-label={ __('Submit') }
            >
                { __('Subscribe') }
            </button>
        );
    }

    renderFormBody(): ReactElement {
        const { device: { isMobile } } = this.props;

        return (
            <>
                <If condition={ !isMobile }>
                    <Image src={ background } alt="" isPlain />
                </If>
                <If condition={ isMobile }>
                    <Image src={ background_mobile } alt="" isPlain />
                </If>
                <div block="FieldContainer">
                    <div>
                        <p block="newsletter_subtitle">{ __('Motorparts') }</p>
                        <p block="newsletter_title">{ __('Newsletter.title') }</p>
                        <p block="newsletter_desc">{ __('Newsletter.subTitle') }</p>
                    </div>
                    <div block="FieldForm" elem="Body">
                        <div block="FieldForm" elem="InputField">
                            { (this.renderSection(this.fieldMap()[0])) }
                        </div>
                        <div>
                            { (this.renderSection(this.fieldMap()[1])) }
                        </div>
                        { this.renderActions() }
                    </div>
                </div>
            </>
        );
    }

    render(): ReactElement {
        return (
            <div block="NewsletterSubscription Container">
                { super.render() }
            </div>
        );
    }
}

export default NewsletterSubscriptionComponent;
